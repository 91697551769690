import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios'; // Assuming you're using axios for API calls

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [groups, setGroups] = useState([]);
    const [csrfToken, setToken] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch the authentication status and groups from your Django backend
        const fetchAuthData = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/api/auth/status'); // Adjust endpoint as needed
                if (response.data.isAuthenticated) {
                    setUser(response.data.user);
                    setGroups(response.data.groups);
                    setToken(response.data.csrfToken);
                } else {
                    setUser(null);
                    setGroups([]);
                    setToken('');
                }
            } catch (error) {
                console.error("Failed to fetch auth data", error);
                setUser(null);
                setGroups([]);
                setToken('');
            }
            setLoading(false);
        };
        fetchAuthData();
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated: !!user, user, groups, loading, csrfToken }}>
            {children}
        </AuthContext.Provider>
    );
};
